"use client"

import { Footer } from "./Footer"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useEffect,useRef } from "react"
// import useScroll from "../../hooks/useScroll"

gsap.registerPlugin(ScrollTrigger)

export const FooterTrigger =()=>{
  const gsapRef= useRef<HTMLDivElement>(null);

    useEffect(()=>{
      if(!gsapRef){
        return
      }
   
      gsap.registerPlugin(ScrollTrigger)
      ScrollTrigger.refresh()
 
       let ctx = gsap.context(() => {
          const footerAni = gsap.fromTo(".bottom-footer .letter", {
            yPercent:()=> 100,
          },{
            yPercent:()=> 0,
            stagger:.07,
            duration:.8,
            ease:"expo.out",
          })
          footerAni.pause();
  
          const tl = gsap.timeline({
            scrollTrigger:{
              trigger:gsapRef.current,
              start:()=>"top center",
              end:()=> "bottom top",
              // markers:true,
              onEnter:()=> {footerAni.play()},
              onLeaveBack:()=> {footerAni.reverse()},
              // scrub:.1,
            },
            ease: "none",
          });
         
        
       

          }, gsapRef);


          return () => {
            ctx.revert();
            }

    },[])

    return (
      <div ref={gsapRef} >
        <Footer/>
      </div>
    )
}