"use client";
import { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie"
import Link from 'next/link';
import posthog from "posthog-js"
import googleAnalytics from "@analytics/google-analytics"
import Analytics from "analytics"
// import mixpanelPlugin from "@analytics/mixpanel"
// import fullStoryPlugin from "@analytics/fullstory"
import { Router } from "next/router";

const IS_BROWSER = typeof window !== "undefined";
const isProd = process.env.NODE_ENV === "production";

const initAnalytics = () =>{
  const analytics = Analytics({
    app: "buenro.com",
    plugins:
      IS_BROWSER
        ? [
            googleAnalytics({
              measurementIds: ["G-BENTCL3KMQ"],
            }),
            // mixpanelPlugin({
            //  token: process.env.NEXT_PUBLIC_MIXPANEL,
            // }),
            // fullStoryPlugin({
            //  org: "o-1KR6B0-na1",
            // }),
          ]
        : [],
  });
  return analytics;
 }
const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState();

  const consentRef = useRef(false);
  // console.log(process.env)
  const runAnalytics = () => {
    const analytics = initAnalytics();
    runAnalyticsPage(analytics);
    Router.events.on("routeChangeComplete", handleRouteChange);
    // window.location.reload();

    // console.log(analytics)

   }

  const runAnalyticsPage = (analytics) => {
    // Fire initial page view
    if ( isProd ) {
      analytics.page();
    }
  };

  const handleRouteChange = (analytics) => {
    if ( isProd ) {
      requestAnimationFrame(() => {
        analytics.page();
      });
    }
  };

  useEffect(() => {
    
    const cookiesConsent = Cookies.get("CookiesConsent");
    if (cookiesConsent === "true") {
      runAnalytics();
    } else if(cookiesConsent === "false") {
      return 
    }
    
    else{
      setShowBanner(true);
    }
   }, []);

  const acceptCookies = () => {
    posthog.opt_in_capturing(); // new
    setShowBanner(()=>false);
    Cookies.set("CookiesConsent", "true");
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "consent",
      ad_storage: "granted",
      analytics_storage: "granted",
      gtag_id: "G-BENTCL3KMQ",
    });
    runAnalytics();
   }

   const declineCookies = () => {
    posthog.opt_out_capturing(); // new
    setShowBanner(()=>false);
    // Delete all cookies except CookiesConsent
    const allCookies = document.cookie.split(';');
    for (let i = 0; i < allCookies.length; i++) {
      const cookie = allCookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      
      // Only delete the cookie if it's not CookiesConsent
      if (name !== 'CookiesConsent') {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      }
    }
    // Set CookiesConsent cookie to "false" after all other cookies have been deleted
    Cookies.set("CookiesConsent", "false");
   };
  return (

    <div className={`w-full h-[3rem] bottom-0 fixed top-auto z-[998] ${showBanner? "block" : "hidden"}`}>
      <div className="items-center absolute backdrop-blur-[10px] left-[50%] bottom-[1.5rem] pl-[.7em] translate-x-[-50%] bg-[rgba(0,0,0,0.55)] text-white-100 flex p-[.1em] border-[rgba(128,128,128,0.35)] border-[.07rem] rounded-[2rem] tablet:text-[1.7rem]
      mobile:flex mobile:flex-col mobile:p-[.6rem] mobile:text-left mobile:gap-[1rem] mobile:text-[1rem] mobile:rounded-[1.7rem] mobile:w-[95%] mobile:pt-[1.3rem] mobile:bg-white-100 mobile:text-black ">
        <p className="text-[.33em] w-[63ch] pr-[.4rem] mobile:w-[95%] mobile:text-[.77rem] mobile:pr-[0]">
          We use cookies to enhance your experience on our website. By
          continuing to use our site, you consent to our use of cookies.
       
        <Link className="text-[1em] px-[.1em] mobile:text-[.77rem]" href="/privacy-policies"> Our <span className="underline text-[#BFBFBF]">Cookie Policy</span>,</Link>
        <button
            className="text-[1em] underline text-[#BFBFBF] mobile:text-[.77rem]" 

            onClick={declineCookies}
          >
            Decline
          </button>
          </p>
        <div className="flex gap-[1em] mobile:w-full mobile:text-[1rem]">
          
          <button
            className="rounded-full text-black py-[.5em] px-[1em] bg-white-100 mobile:w-full mobile:p-[.9rem]  mobile:bg-black mobile:text-white-100"
            onClick={acceptCookies}
          >
            <p className="text-[.4em] mobile:text-[.77rem]">Consent</p>

          </button>
        </div>
      </div>
    </div>
  );
};
export default CookieBanner;