"use client"
import React, { createContext, useContext, useReducer } from "react";
import { mainReducer } from './reducer';

interface ThemeContextType {
  lenisScroll: { [key: string]: any };
  pageTitle: string;
  cookie:boolean,
  initLoad:boolean,
  pointer: {
  isHover: boolean;
  color: string;
  text: string;
  };
  setLenisScroll: (sy: any) => void;
  changeCookie: (pay: any) => void;
 }

 
const initState = {
 lenisScroll: {},
 pageTitle: 'home',
 loading:false,
 initLoad:true,
 cookie:false,
 pointer: {
   isHover: false, 
   color: "",
   text: "",
 },
}

// Create a context object
export const ThemeContext = createContext<ThemeContextType | any>(undefined);

// Create a provider component
export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
 const [state, dispatch] = useReducer(mainReducer, initState);
 const changePointer = (pay: any) => {
   dispatch({ type: 'CHANGEHOVER', payload: pay })
 }
 const setLenisScroll = (sy: any) => {
   dispatch({ type: 'SCROLLED', payload: sy })
 }
 const changeLoading = (sy: any) => {
  dispatch({ type: 'CHANGELOADING', payload: sy })
}
 const changeTitle = (pay: any) => {
   dispatch({ type: 'CHANGETITLE', payload: pay })
 }
 const changeCookie = (pay: any) => {
  dispatch({ type: 'CHANGECOOKIE', payload: pay })
 }
 const changeInitLoad = (pay: any) => {
  dispatch({ type: 'CHANGEINITLOAD', payload: pay })
 }
 

 return (
   <ThemeContext.Provider value={{ ...state, setLenisScroll,changeCookie,changeLoading,changeInitLoad }}>
     {children}
   </ThemeContext.Provider>
 )
}
