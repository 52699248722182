interface State {
  lenisScroll: { [key: string]: any };
  pageTitle: string;
  cookie:boolean,
  initLoad:boolean,
  pointer: {
   isHover: boolean;
   color: string;
   text: string;
  };
 }
 interface Action {
  type: string;
  payload: any;
 }
export const mainReducer = (state:State, action:Action)=>{
  if (action.type === 'CHANGETITLE'){
    return {...state, pageTitle: action.payload}
   }
  if(action.type === "SCROLLED"){
    return {...state, lenisScroll: action.payload}
  }
  if(action.type === "CHANGELOADING"){
    return {...state, loading: action.payload}
  }
  if (action.type === 'CHANGECOOKIE'){
    return {...state, cookie: action.payload}
   }
  if (action.type === 'CHANGEINITLOAD'){
    return {...state, initLoad: false}
  }
  if(action.type === "CHANGEPOINTER"){
    return {...state, pointer: {...state.pointer, ...action.payload}}
  }
  return state;
}